<template>
  <div id="typing-baloon">
    <div id="blink">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypingBalloon",
  mounted () {
    this.$el.scrollIntoView({ behavior: "smooth" });
  },
};
</script>

<style scoped lang="scss">
#typing-baloon {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  width: fit-content;
  overflow-wrap: anywhere;
  max-width: 75%;
  position: relative;
  margin: 8px 0;
  font-family: "Mark Pro Light", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  text-align: justify;
  background: #efefef;
  color: #333333;
  margin-right: auto;
  margin-left: 0;
}

#blink {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 3px;
    background: #333333;
    animation: blink 1.0s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.8s;
    }

    &:nth-child(3) {
      animation-delay: -0.6s;
    }
  }
}

@keyframes blink {
  0%,
  60%,
  100% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}
</style>