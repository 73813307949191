<template>
  <div id="chat-button" @click="clickChatBalloon">
    <div id="avatar">
    </div>
    <transition name="fade">
      <div id="avatar-balloon" v-show="showBalloon">
        <p>Olá, precisa de ajuda?</p>
      </div>
    </transition>
    <div id="remove-iara" @click="removeIara">
      <font-awesome-icon
          :icon="['fa', 'times']"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: "ChatButton",

    props: {
        publicUrl: String,
    },

    data(){
        return {
            showBalloon: false
        }
    },

    methods: {
        clickChatBalloon(){
            this.$emit('click-chat-balloon');
        },

        /** 
        * * Envia mensagem para o pai no App.vue
        */
        removeIara(){
            this.$emit('remove-iara');
        }
    },

    mounted() {

        setTimeout(() => {
            this.showBalloon = true;
        }, 2000)
    }

}
</script>

<style scoped lang="scss">

    #chat-button, #avatar {
        width: 67px;
        height: 67px;
    }

    #chat-button{
        position: absolute;
        bottom: var(--bottom);
        right: var(--right);
        cursor: pointer;
    }

    #avatar{
        background: url(../assets/img/iara_formal.png) center/cover #FFFFFF;
        border-radius: 33.5px;
        //filter: drop-shadow(0px 0.25px 3px rgba(0, 0, 0, 0.039)) drop-shadow(0px 2.75px 9px rgba(0, 0, 0, 0.19));
        //box-shadow: 0 0.5px 1.75px rgba(0, 0, 0, 0.039), 0 1.85px 6.25px rgba(0, 0, 0, 0.19);

        background-size: 108px;
        background-position-y: top;
        background-position-x: 89px;
    }

    #avatar-balloon{
        padding: 8px 0;
        align-items: center;
        box-sizing: border-box;
        text-align: center;

        position: absolute;
        width: 180px;
        height: 36px;
        right: 75px;
        bottom: 15px;
        background: var(--primary_color);
        //box-shadow: 0 0.5px 1.75px rgba(0, 0, 0, 0.039), 0 1.85px 6.25px rgba(0, 0, 0, 0.19);
        border-radius: 8px;

        p{
            margin: 0;
            font-family: 'Mark Pro Light', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #FFFFFF;

        }
    }

    #remove-iara {
    color: var(--primary_color);
    position: absolute;
    bottom: 56px;
    right: 0;
    }

    .fade-enter-active {
    transition: all .5s ease-in-out /*cubic-bezier(0.48,-0.05, 0.49, 1.13)*/;
    }

    .fade-leave-active {
    transition: all .5s ease-in-out /*cubic-bezier(0.48,-0.05, 0.49, 1.13)*/;
    }

    .fade-enter, .fade-leave-to
    /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }

</style>