<template>
  <div
      id="message-baloon"
      v-bind:class="
      message.from === 'cliente' ? 'sended-message' : 'received-message'
    "
  >
    <span v-if="message.from == 'operador'" id="message-title" v-html="formatedMessage"></span>
    <span v-else id="message-title" v-text="textString"></span>
    <button
        id="message-button"
        v-show="showAfterRespond"
        v-for="(button, index) in message.message.buttons"
        :key="index"
        @click.stop="clickButton(button)"
    >
      {{ button }}
    </button>

    <span
        id="message-footer"
        v-if="message.message.footer"
        v-html="message.message.footer.replace(/(?:\r\n|\r|\n)/g, '<br>')"
    ></span>
  </div>
</template>

<script>
export default {
  name: "MessageBalloon",
  props: {
    message: Object,
  },
  data(){
    return {
      clicked: false,
      clickDelay: false,
    }
  },
  computed: {
    showAfterRespond() {
      return !(this.message.message.oneClick && this.clicked);
    },
    textString(){
      let Message = this.message.message.text;
      return Message;
    },
    formatedMessage() {
      let formatedMessage = this.message.message.text;
      if (!this.message.static) {
        let regex = /((https?:\/\/(www\.)?)|(www))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//={"}]*)?/gi;
        let links = this.message.message.text.match(regex);
        if (links) {
          links.forEach((link) => {
            formatedMessage = formatedMessage.replace(
                link,
                `<a style="" target="_blank" href='${(link.includes('https://') || link.includes('http://')) ? link : 'https://' + link}'>${link}</a>`
            );
          });
        }
      }
      return formatedMessage.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
  methods: {
    scrolltoNewestMessage() {
      this.$el.scrollIntoView({ behavior: "smooth" });
    },
    clickButton(message) {
      if(this.clickDelay){
        return;
      }
      this.clickDelay = true;
      setTimeout(() => {
        this.clickDelay = false;
      }, 1000);
      this.$emit("click-button", message);
      this.clicked = true;
    },
  },
  mounted() {
    this.scrolltoNewestMessage();
  },
};
</script>

<style scoped lang="scss">

.sended-message,
.received-message {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  width: fit-content;
  overflow-wrap: anywhere;
  max-width: 75%;
  position: relative;
  margin: 8px 0;
  font-family: 'Mark Pro Light', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  text-align: justify;
}

.sended-message {
  background: var(--primary_color);
  color: #ffffff;
  margin-left: auto;
  margin-right: 0;
}

.received-message {
  background: #efefef;
  color: #333333;
  margin-right: auto;
  margin-left: 0;
}
#message-button {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  background: var(--primary_color);
  border-radius: 8px;
  margin: 6px 0;
  font-family: 'Mark Pro Light', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.87);
  border: solid 1px var(--primary_color);
  text-align: left;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: #efefef;
    color: var(--primary_color);
    cursor: pointer;
  }
}
</style>